import { useEffect, useRef } from 'react'

export default function useEventCallback(fn) {
    const ref = useRef(() => {
      throw new Error('Cannot call an event handler while rendering.');
    });
  
    useEffect(() => {
      ref.current = fn;
    }, [fn]);
  
    return fn;
  }