/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import { welcomeWrapper } from './src/components/welcome.module.scss'
const React = require("react")
const Welcome = require("./src/components/Welcome").default


export const wrapPageElement = ({ element }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return (
  <>
    <Welcome />
    {element}
  </>
  )
}

export const onRouteUpdate = () => {
    setTimeout(() => {
      if (document.querySelector(`.${welcomeWrapper}`)) {
        document.querySelector(`.${welcomeWrapper}`).style.display = "none";
      }
    }, 4000);
  };

  export const onInitialClientRender = () => {
    setTimeout(() => {
      if (document.querySelector(`.${welcomeWrapper}`)) {
        document.querySelector(`.${welcomeWrapper}`).style.display = "none";
      }
    }, 4000);
  }

  // export const onInitialClientRender = () => {
  //   setTimeout(() => {
  //     if (document.querySelector(`.${welcomeWrapper}`)) {
  //       document.querySelector(`.${welcomeWrapper}`).style.display = "none";
  //     }
  //   }, 4000);

  //   let imgs = document.querySelectorAll('img');

  //   for (let i = 0; i < imgs.length; i++) {
  //     imgs[i].setAttribute('draggable', false)
  //   }
  // }



  // export const onClientEntry= () => {

  //   fetch(kroyTourQuery)
  //     .then(response => response.json()) // parse JSON from request
  //     .then(resultData => {
  //       sessionStorage.setItem("kroyFetchedTourDates", JSON.stringify(resultData));
  //   })

    
  // };

  // export const onClientEntry= () => {
  //   const content = document.querySelector(`.${wrapper}`)

  //     content.addEventListener("DOMMouseScroll", (event) => {
  //        event.preventDefault();
  //        const deltaY = event.detail;
  //        content.scrollTop += deltaY * 0.8;
  //     });
  // };

// export const onRouteUpdate = () => {
//     setTimeout(() => {
//       if (document.getElementById("kroy-loader")) {
//         document.getElementById("kroy-loader").style.display = "none";
//       }
//     }, 4000);
//   };
