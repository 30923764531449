import * as React from 'react'
import Logo from '../svg/Logo'
import useSessionStorage from '../utils/useSessionStorage'
import useTimeout from '../utils/useTimeout'
import useLockedBody from '../utils/useLockedBody'
import { backingPulse, logoPulse, welcomeWrapper, welcomeInner } from './welcome.module.scss'
// import { welcomeTime, welcomeLockedTime } from '../utils/consts'

export default function Welcome() {

    const [seen, setSeen] = useSessionStorage('kroy-loader', false)
    const [loading, setLoading] = React.useState(true)

    const handleSession = () => {
        setSeen(true);
    }

    
    useTimeout(handleSession, 4000);

    const handleLocked = () => {
        setLoading(false)
    }

    useTimeout(handleLocked, 3000);
    useLockedBody(!seen ? loading : null, 'root')


    return (
        <div className={[`h-screen w-screen fixed top-0 right-0 bottom-0 left-0 !m-0 !p-0 z-[9999]`, welcomeWrapper].join(" ")} 
        style={{height: '100dvh', display: seen ? 'none' : 'block'}}
        suppressHydrationWarning={true}
        >
            <div className={[`lg:py-spacing lg:px-spacing-2x p-padding h-full w-full flex flex-col justify-center items-center bg-dark-default text-light-default`, welcomeInner].join(" ")}>
                <Logo className={[`lg:max-w-[6.25rem] md:max-w-[5.5rem] xs:max-w-[5rem] max-w-[30vw] w-full`, logoPulse].join(" ")} />
            </div>
        </div>
    )
}

export const Welcome2 = () => {

    const [seen, setSeen] = useSessionStorage('kroy-loader', false)
    const [loading, setLoading] = React.useState(true)

    const handleSession = () => {
        setSeen(true);
    }

    useTimeout(handleSession, 4000);
    
    // useTimeout(handleSession, welcomeTime);

    const handleLocked = () => {
        setLoading(false)
    }

    useTimeout(handleLocked, 3000);
    // useTimeout(handleLocked, welcomeLockedTime);
    useLockedBody(!seen ? loading : null, 'root')

    return (
        <div id={`kroy-loader`} className={[`h-screen w-screen fixed top-0 right-0 bottom-0 left-0 !m-0 !p-0 z-[9999]`, backingPulse].join(" ")} 
        style={{height: '100dvh', display: seen ? 'none' : 'block'}}
        // style={{height: '100dvh', animationDuration: [welcomeTime, 'ms'].join(""), animationDelay: [welcomeLockedTime, 'ms'].join("")}}
        >
            <div className={[`lg:p-spacing-y p-spacing-2x h-full w-full flex flex-col justify-center items-center bg-dark-default text-light-default`, welcomeInner].join(" ")}>
                <Logo className={[`max-w-[6.75rem] w-full`, logoPulse].join(" ")} />
            </div>
        </div>
    )
}