import React from 'react'

const Logo = ({ className, ...rest }) => {

    return (
        <svg role={`presentation`} className={className ? className : null} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 720 167" {...rest}>
    <path fill={`currentColor`} d="M97.3,82.8l70.2,79.6H110l-50.8-60.2H43.8v60.2H0V4.6h43.8v58.6h19l43.4-58.6h55L97.3,82.8z M337.5,135.4
	c1.9,11.4,3.9,20.5,6,27.1h-46.4c-0.1-0.6-1-5.9-2.5-15.9c-1.5-10-3.4-16.8-5.7-20.4c-2.1-3.8-4.7-6.5-7.7-8.2
	c-3-1.7-7.1-2.6-12.1-2.6h-52v47.1h-43.8V4.6H283c17.8,0,31.4,3.9,40.6,11.8c9.2,7.9,13.8,20.3,13.8,37.3v3.8
	c0,18.5-8.4,30.8-25.1,37V95c5.6,2,10,4.4,13.3,7.2c3.2,2.9,5.7,6.7,7.4,11.5C334,116.7,335.6,124,337.5,135.4z M293.1,57.8
	c0-5.6-1.4-9.4-4.1-11.4c-2.7-2-7.9-3-15.5-3h-56.7v35.2h56.5c7.4,0,12.6-1.1,15.5-3.4c2.9-2.3,4.3-6.2,4.3-12V57.8z M528.6,48.9
	c3.3,10,5,21.6,5,34.7c0,13.2-1.7,24.7-5,34.6c-3.3,9.9-7.7,17.9-13.2,24c-5.5,6.1-12.3,11-20.3,14.8c-8.1,3.8-16.4,6.3-25,7.7
	c-8.6,1.4-18.2,2-28.8,2c-10.6,0-20.2-0.7-28.8-2c-8.6-1.4-16.9-3.9-25.1-7.7c-8.1-3.8-15-8.7-20.4-14.8c-5.5-6.1-9.8-14.1-13.1-24
	c-3.2-9.9-4.8-21.5-4.8-34.6c0-13.2,1.6-24.7,4.8-34.7c3.2-10,7.6-18,13.1-24.1c5.5-6.1,12.3-11,20.4-14.8
	c8.1-3.8,16.5-6.3,25.1-7.7c8.6-1.4,18.2-2,28.8-2c10.6,0,20.2,0.7,28.8,2c8.6,1.4,16.9,3.9,25,7.7c8.1,3.8,14.9,8.7,20.3,14.8
	C520.9,30.8,525.3,38.9,528.6,48.9z M488.6,83.6c0-9.2-0.7-16.7-2.1-22.2c-1.4-5.6-4-10-7.8-13.4c-3.8-3.4-8.6-5.6-14.3-6.8
	c-5.8-1.1-13.5-1.7-23.2-1.7c-9.7,0-17.4,0.6-23.2,1.7c-5.8,1.1-10.5,3.4-14.3,6.8c-3.8,3.4-6.4,7.9-7.7,13.4c-1.3,5.6-2,13-2,22.2
	s0.7,16.6,2,22.1c1.3,5.5,3.9,9.9,7.7,13.3c3.8,3.4,8.6,5.6,14.3,6.8c5.8,1.1,13.5,1.7,23.2,1.7c9.7,0,17.4-0.6,23.2-1.7
	c5.8-1.1,10.5-3.4,14.3-6.8c3.8-3.4,6.4-7.8,7.8-13.3C487.9,100.2,488.6,92.9,488.6,83.6z M667.5,4.6L624.3,70l-0.8,3.2h-1.3
	l-0.8-3.2L578.2,4.6h-52.7l75.4,106.7v51.2h43.8v-51.2L720,4.6H667.5z"/>
</svg>


    )
}

export default Logo